<rp-table-mobile [data]="data().offers" [itemsLength]="data().itemsCount" [template]="templateRef" (pagination)="onPagination($event)" />
<ng-template #templateRef let-data="data">
  <div class="cell" (click)="onSelect(data)">
    <div class="cell__info">
      <div class="cell__item">
        <div class="cell__id">{{ data.id }}</div>
      </div>
      @if (data.isExclusive) {
        <div class="cell__item text-end">
          <rp-icon [iconName]="icons.Exclusive"></rp-icon>
        </div>
      } @else if (data.isHidden) {
        <rp-icon iconName="eye-off" />
      }
    </div>
    <div class="cell__info">
      <div class="cell__item">
        <div class="label name">{{ 'general.name' | translate }}</div>
        <div class="value name">{{ data.name }}</div>
      </div>

      <div class="cell__item text-end">
        <div class="label name">{{ 'general.geo' | translate }}</div>
        <div class="value name">{{ data.geos }}</div>
      </div>
    </div>
  </div>
</ng-template>
