@if (menuItem().url) {
  <a class="rp-sidebar_nav-item" mat-list-item [href]="menuItem().url">
    <div class="rp-sidebar_nav-item-content">
      @if (menuItem().icon) {
        <rp-icon [iconName]="menuItem().icon" />
      }

      <span class="label">{{ menuItem().label | translate }}</span>
    </div>
  </a>
} @else if (menuItem().children) {
  <a
    mat-list-item
    class="rp-sidebar_nav-item"
    routerLinkActive="active"
    [routerLink]="menuItem().link"
    (mouseenter)="onTriggerEnter(menuTrigger)"
    (mouseleave)="onTriggerLeave(menuTrigger)"
  >
    <div class="rp-sidebar_nav-item-menu-trigger" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>

    <div class="rp-sidebar_nav-item-content">
      @if (menuItem().icon) {
        <rp-icon [iconName]="menuItem().icon" />
      }

      <span class="label">{{ menuItem().label | translate }}</span>

      @if (!isDesktop()) {
        <rp-icon (click)="onSubmenuToggle($event)" [iconName]="isSubMenuOpened() ? 'chevron-up' : 'chevron-down'" />
      }
    </div>

    <mat-menu hasBackdrop="false" class="rp-menu rp-sidebar-menu" #menu="matMenu">
      <div class="rp-menu_content" (mouseenter)="onMenuEnter(menuTrigger)" (mouseleave)="onMenuLeave(menuTrigger)">
        @for (child of menuItem().children; track $index) {
          <rp-sidebar-menu-item [menuItem]="child" />
        }
      </div>
    </mat-menu>
  </a>

  @if (!isDesktop()) {
    <div class="rp-sidebar_submenu" [class.expanded]="isSubMenuOpened()">
      <div class="rp-sidebar_submenu-wrapper">
        @for (child of menuItem().children; track $index) {
          <rp-sidebar-nav-item [menuItem]="child" isDesktop="false" />
        }
      </div>
    </div>
  }
} @else {
  <a class="rp-sidebar_nav-item" mat-list-item routerLinkActive="active" [routerLink]="menuItem().link">
    <div class="rp-sidebar_nav-item-content">
      @if (menuItem().icon) {
        <rp-icon [iconName]="menuItem().icon" />
      }

      <span class="label">{{ menuItem().label | translate }}</span>
    </div>
  </a>
}
