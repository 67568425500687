import {
  EnvironmentProviders,
  makeEnvironmentProviders,
  importProvidersFrom,
  inject,
  APP_INITIALIZER,
} from '@angular/core';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  provideLuxonDateAdapter,
} from '@angular/material-luxon-adapter';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { languagesLocaleRecord, Languages } from '@rp/shared/models';
import { LanguageService } from '@rp/shared/services';
import { Observable, switchMap } from 'rxjs';

export function localeFactory(): string {
  const translateService = inject(TranslateService);

  return languagesLocaleRecord[translateService.currentLang as Languages];
}

export function translationInitFactory(
  translateService: TranslateService,
  languageService: LanguageService,
) {
  return (): Observable<void> => {
    const currentLanguage = languageService.getCurrentLanguage();

    translateService.setDefaultLang(currentLanguage);

    return languageService.setTranslation(currentLanguage).pipe(
      switchMap(() => {
        return translateService.use(currentLanguage);
      }),
    );
  };
}

export const provideTranslate = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: translationInitFactory,
      deps: [TranslateService, LanguageService],
      multi: true,
    },
    importProvidersFrom([TranslateModule.forRoot(), MatNativeDateModule]),
    provideLuxonDateAdapter({
      parse: {
        dateInput: ['dd.MM.yyyy'],
      },
      display: {
        dateInput: 'dd.MM.yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'dd.MM.yyyy',
        monthYearA11yLabel: 'MMM yyyy',
      },
    }),
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { firstDayOfWeek: 1 } },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: localeFactory,
    },
  ]);
