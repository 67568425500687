<div class="rp-languages" #trigger="matMenuTrigger" [matMenuTriggerFor]="menu" (click)="onMenuTriggerClick($event)">
  <rp-icon [iconName]="flagIcon()" size="s" />

  <span class="label">{{ currentLanguage() }}</span>

  <rp-icon [iconName]="trigger.menuOpen ? icons.ChevronUp : icons.ChevronDown" size="s" />
</div>

<mat-menu class="rp-menu rp-languages-menu" hasBackdrop="false" xPosition="before" #menu="matMenu">
  <div class="rp-menu_content">
    @for (item of filteredLanguages(); track $index) {
      <div mat-menu-item disableRipple="true" class="rp-menu_item" (click)="handleLanguageChange(item.language)">
        <span class="label">
          <rp-icon [iconName]="item.icon" size="s" />

          {{ item.language }}
        </span>
      </div>
    }
  </div>
</mat-menu>
