import { inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { map, Observable } from 'rxjs';

import { UserAuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class UserAuthGuard implements CanActivate {
  private _userAuthService = inject(UserAuthService);

  canActivate(): Observable<boolean> | boolean {
    if (this._userAuthService.isAuthenticated()) {
      return this._userAuthService.getPersonalInfo().pipe(
        map(personalInfo => {
          if (!personalInfo) {
            this._userAuthService.logout();
          }

          return Boolean(personalInfo);
        }),
      );
    }

    this._userAuthService.logout();

    return false;
  }
}
