import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import {
  IconComponent,
  IconName,
  StatusComponent,
  TableMobileComponent,
} from '@rp/shared/components';
import { PaginationRequest } from '@rp/shared/models';

import { Offer, OffersResponse } from '../../../../models';

@Component({
  selector: 'rp-offers-webmaster-mobile-table',
  standalone: true,
  templateUrl: './offers-webmaster-mobile-table.component.html',
  styleUrl: './offers-webmaster-mobile-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, TableMobileComponent, TranslateModule, StatusComponent],
})
export class OffersWebmasterMobileTableComponent {
  data = input.required<OffersResponse>();

  select = output<Offer>();
  pagination = output<PaginationRequest>();

  protected readonly icons = IconName;

  onSelect(webmaster: Offer): void {
    this.select.emit(webmaster);
  }

  onPagination(pagination: PaginationRequest): void {
    this.pagination.emit(pagination);
  }
}
