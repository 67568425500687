<div>
  <h2 class="not-found__title">{{ 'notFound.title' | translate }}</h2>
  <p class="not-found__message">{{ 'notFound.message' | translate }}</p>
</div>

<div class="not-found__body">
  <p>{{ 'notFound.descriptionHeader' | translate }}</p>
  <p>{{ 'notFound.descriptionSubHeader' | translate }}</p>

  <ul class="not-found__body_actions">
    <li>
      <a href="/">
        {{ 'notFound.returnToHomePage' | translate }}
      </a>
    </li>

    <li>
      <a href="">
        {{ 'notFound.contactSupport' | translate }}
      </a>
    </li>
  </ul>

  <p>{{ 'notFound.assistance' | translate }}</p>
</div>
