import { inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { map, Observable } from 'rxjs';

import { AdminAuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivate {
  private _adminAuthService = inject(AdminAuthService);

  canActivate(): Observable<boolean> | boolean {
    if (this._adminAuthService.isAuthenticated()) {
      return this._adminAuthService.getPersonalInfo().pipe(
        map(personalInfo => {
          if (!personalInfo) {
            this._adminAuthService.logout();
          }

          return Boolean(personalInfo);
        }),
      );
    }

    this._adminAuthService.logout();

    return false;
  }
}
