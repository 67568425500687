import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import {
  PayoutBalance,
  FINANCE_WEBMASTER_PROVIDER_TOKEN,
  FINANCE_ADMIN_PROVIDER_TOKEN,
  HttpFinanceWebmasterProvider,
  HttpFinanceAdminProvider,
} from '@rp/pages/finance';
import { ProfileTarget, TargetApp } from '@rp/shared/models';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CentsToDollarsPipe } from '@rp/shared/pipes';

import { UserAuthService } from '../../../../services';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FinanceService } from '../../../../../../../pages/finance/src/lib/services/finance.service';

@Component({
  selector: 'rp-my-balance',
  standalone: true,
  templateUrl: './my-balance.component.html',
  styleUrl: './my-balance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CentsToDollarsPipe, AsyncPipe, TranslateModule],
  /**
   * ToDo: This component placed in core lib but depends on FinanceService
   * which is placed in feature lib. We should change this dependency during refactoring
   */
  providers: [
    FinanceService,
    { provide: FINANCE_ADMIN_PROVIDER_TOKEN, useClass: HttpFinanceAdminProvider },
    { provide: FINANCE_WEBMASTER_PROVIDER_TOKEN, useClass: HttpFinanceWebmasterProvider },
  ],
})
export class MyBalanceComponent {
  balance$: Observable<PayoutBalance>;

  private _authService = inject(UserAuthService);
  private _financeService = inject(FinanceService);

  constructor() {
    this._getBalance();
  }

  private _getBalance(): void {
    const userType = this._authService.getPersonalInfoFromStorage()?.userType;

    if (userType !== ProfileTarget.Webmaster) {
      return;
    }

    this.balance$ = this._financeService.getInstance(TargetApp.Webmaster).getMyBalance();
  }
}
