<mat-sidenav-container autosize hasBackdrop="false" class="rp-sidebar">
  <mat-sidenav [mode]="isDesktop() ? 'side' : 'over'" [opened]="isDesktop()">
    <div class="rp-sidebar_top">
      @if (isDesktop()) {
        <rp-logo size="m" />
      } @else if (headerConfig.havePersonalManager) {
        <div class="rp-sidebar__user-info">
          <rp-icon class="user-info__icon" [iconName]="icons.Avatar" size="l" />
          <div class="user-info__content">
            <span class="user-info__label">Your manager</span>
            <span class="user-info__value">Name Surname</span>
          </div>
        </div>
      }
    </div>

    <mat-nav-list class="rp-sidebar_nav" disableRipple>
      @if (!isDesktop()) {
        <h3>{{ 'menu.navigation' | translate }}</h3>
      }

      @for (item of menuItemsList(); track $index) {
        @if (item.shouldShow) {
          <rp-sidebar-nav-item [isDesktop]="isDesktop()" [menuItem]="item" />
        }
      }

      <div class="rp-sidebar_nav-footer">
        @for (item of footerMenuItemsList(); track $index) {
          @if (item.shouldShow) {
            <rp-sidebar-nav-item [isDesktop]="isDesktop()" [menuItem]="item" />
          }
        }
      </div>
    </mat-nav-list>

    <div class="rp-sidebar_footer">
      @if (!isDesktop()) {
        <div class="rp-sidebar_footer-time">
          <span>UTC {{ currentTime$ | async | date: clockTimeFormat : 'UTC' }}</span>
        </div>
      }

      <div class="rp-sidebar_footer-socials">
        @for (item of socialItemsList(); track $index) {
          <a class="rp-sidebar_footer-item" [href]="item.url">
            <rp-icon class="rp-sidebar_footer-socials-icon" [iconName]="item.icon!" />

            <rp-icon class="rp-sidebar_footer-socials-icon-hover" [iconName]="item.icon + '-hover'" />
          </a>
        }
      </div>

      @if (!isDesktop()) {
        <rp-languages (languageChanged)="handleLanguageChange()" />
      }
    </div>
  </mat-sidenav>

  <ng-content mat-sidenav-content />
</mat-sidenav-container>
