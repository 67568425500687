import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { isFormEmpty } from '@rp/utils';
import {
  ButtonComponent,
  IconName,
  InputComponent,
  IntegerOnlyDirective,
  SelectComponent,
} from '@rp/shared/components';
import {
  Entity,
  SelectDropdownItem,
  ToFormControls,
  USER_STATUSES_SELECT_ITEMS,
  UserStatuses,
} from '@rp/shared/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { from, switchMap } from 'rxjs';

import { OffersFilterMappings, OffersFilter } from '../../../../models';
import { CountriesService } from '../../../../services';

@Component({
  selector: 'rp-offers-table-filters',
  standalone: true,
  templateUrl: './offers-table-filters.component.html',
  styleUrl: './offers-table-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    SelectComponent,
    ButtonComponent,
    TranslateModule,
    IntegerOnlyDirective,
  ],
})
export class OffersTableFiltersComponent {
  filter = input.required<OffersFilter | null>();
  filterMappings = input.required<OffersFilterMappings>();
  filterForm = input.required<FormGroup<ToFormControls<OffersFilter>>>();
  shouldShowRates = input<boolean>(false);

  filterChanged = output<OffersFilter>();

  geoDropdownsValues: ({ label: string } & Entity)[];

  icons = IconName;
  isFormEmpty = isFormEmpty;

  trafficSources = signal<Entity[]>([]);
  verticals = signal<Entity[]>([]);
  geos = signal<Entity[]>([]);
  paymentModels = signal<Entity[]>([]);
  userStatuses = signal<SelectDropdownItem<UserStatuses>[]>(USER_STATUSES_SELECT_ITEMS);

  private _destroyRef = inject(DestroyRef);
  private _countriesService = inject(CountriesService);
  private readonly _translate = inject(TranslateService);

  constructor() {
    this._translate.onLangChange
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        switchMap(langEv => from(this._countriesService.registerLocale(langEv.lang))),
      )
      .subscribe(() => this._setGeoDropdownValues(this.geos()));

    effect(
      () => {
        if (this.filter() && Object.values(this.filter()).length) {
          this.filterForm().patchValue(this.filter(), { emitEvent: false });
        } else {
          this.onResetFilters();
        }

        if (this.filterMappings()) {
          this.trafficSources.set(this.filterMappings().trafficSources);
          this.verticals.set(this.filterMappings().verticals);
          this.geos.set(this.filterMappings().geos);
          this._setGeoDropdownValues(this.geos());
          this.paymentModels.set(this.filterMappings().paymentModels);
        }
      },
      { allowSignalWrites: true },
    );
  }

  onResetFilters(): void {
    this.filterForm().reset();

    this.filterChanged.emit(<OffersFilter>this.filterForm().value);
  }

  onApplyFilters(): void {
    if (this.filterForm().invalid) {
      this.filterForm().markAllAsTouched();

      return;
    }

    this.filterChanged.emit(<OffersFilter>this.filterForm().value);
  }

  private _setGeoDropdownValues(geo: Entity[]): void {
    this.geoDropdownsValues = geo.map(geo => {
      const countryName = this._countriesService.getName(
        geo.name,
        this._translate.currentLang.toLocaleLowerCase(),
      );

      return {
        ...geo,
        label: countryName,
      };
    });
  }
}
