import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { map, Observable, tap } from 'rxjs';
import { environment } from '@rp/environments';
import { LocalStorageService } from '@rp/shared/services';

import { ACCESS_TOKEN, REFRESH_TOKEN, PERSONAL_INFO } from '../constants';
import { RefreshTokenPayload, RefreshTokenResponse } from '../models';
import { PersonalInfo } from '../models/personal-info.interface';

@Injectable({ providedIn: 'root' })
export class AuthBaseService {
  http = inject(HttpClient);
  localStorageService = inject(LocalStorageService);

  adminApiHost = environment.apiConfig.adminApiHost;
  userApiHost = environment.apiConfig.userApiHost;

  isAuthenticated(): boolean {
    const accessToken = this.localStorageService.getStringValue(ACCESS_TOKEN);
    const refreshToken = this.localStorageService.getStringValue(REFRESH_TOKEN);

    return Boolean(accessToken && refreshToken);
  }

  getPersonalInfo(): Observable<PersonalInfo> {
    return this.http
      .get<PersonalInfo>(`${this.userApiHost}/users/personal-info/`)
      .pipe(tap(personalInfo => this._setPersonalInfoToStorage(personalInfo)));
  }

  getPersonalInfoFromStorage(): PersonalInfo | null {
    return this.localStorageService.getValue<PersonalInfo | null>(PERSONAL_INFO);
  }

  refreshToken(): Observable<string> {
    const refresh: RefreshTokenPayload = {
      refresh: this.localStorageService.getStringValue(REFRESH_TOKEN),
    };

    return this.http
      .post<RefreshTokenResponse>(`${this.userApiHost}/auth/token/refresh/`, refresh)
      .pipe(
        map(({ access, refresh }) => {
          this.localStorageService.setValue<string>(ACCESS_TOKEN, access);
          this.localStorageService.setValue<string>(REFRESH_TOKEN, refresh);

          return access;
        }),
      );
  }

  logout(): void {
    this.localStorageService.removeValue(ACCESS_TOKEN);
    this.localStorageService.removeValue(REFRESH_TOKEN);
    this.localStorageService.removeValue(PERSONAL_INFO);

    location.href = environment.loginPageUrl;
  }

  private _setPersonalInfoToStorage(personalInfo: PersonalInfo): void {
    this.localStorageService.setValue<PersonalInfo>(PERSONAL_INFO, personalInfo);
  }
}
