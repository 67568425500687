@if (menuItem().url) {
  <a mat-menu-item disableRipple="true" class="rp-menu_item" [href]="menuItem().url">
    <span class="label">{{ menuItem().label | translate }}</span>
  </a>
} @else if (menuItem().children) {
  <a
    mat-menu-item
    disableRipple="true"
    class="rp-menu_item"
    [routerLink]="menuItem().link || ''"
    (mouseenter)="onTriggerEnter(menuTrigger)"
    (mouseleave)="onTriggerLeave(menuTrigger)"
  >
    <div class="rp-sidebar_menu-item-menu-trigger" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>

    <span class="label">
      {{ menuItem().label | translate }}

      <rp-icon [iconName]="'chevron-right'" size="xs" />
    </span>

    <mat-menu hasBackdrop="false" class="rp-menu rp-sidebar-menu" #menu="matMenu">
      <div class="rp-menu_content" (mouseenter)="onMenuEnter(menuTrigger)" (mouseleave)="onMenuLeave(menuTrigger)">
        @for (child of menuItem().children; track $index) {
          <rp-sidebar-menu-item [menuItem]="child" />
        }
      </div>
    </mat-menu>
  </a>
} @else {
  <a mat-menu-item disableRipple="true" class="rp-menu_item" [routerLink]="menuItem().link || ''">
    <span>{{ menuItem().label | translate }}</span>
  </a>
}
