import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from '@angular/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';

import { LogoComponent } from '@rp/shared/ui';
import { ClockService } from '@rp/common/cdk';
import { Observable } from 'rxjs';
import { environment } from '@rp/environments';
import { IconComponent, IconName, TooltipEllipsisDirective } from '@rp/shared/components';
import { DateFormat } from '@rp/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderStateService } from '@rp/shared/services';

import { MyBalanceComponent } from './components/my-balance/my-balance.component';
import { LanguagesComponent } from '../languages/languages.component';
import { AuthBaseService } from '../../services/auth-base.service';
import { PersonalInfo } from '../../models';

@Component({
  selector: 'rp-header, [rpHeader]',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LogoComponent,
    MyBalanceComponent,
    AsyncPipe,
    DatePipe,
    IconComponent,
    LanguagesComponent,
    RouterLink,
    TranslateModule,
    TooltipEllipsisDirective,
  ],
})
export class HeaderComponent {
  isDesktop = input.required({ transform: booleanAttribute });
  headerTitle = input<string>(null);
  isSidebarCollapsed = input(false, { transform: booleanAttribute });

  isSidebarCollapsedChange = output<boolean>();

  currentTime$: Observable<Date>;
  personalInfo: PersonalInfo | null;
  headerConfig = environment.uiConfig.header;
  icons = IconName;
  clockTimeFormat = DateFormat.TimeWithSeconds;

  headerStateService = inject(HeaderStateService);

  private _clockService = inject(ClockService);
  private _authService = inject(AuthBaseService);

  constructor() {
    this.currentTime$ = this._clockService.nowSubject;
    this.personalInfo = this._authService.getPersonalInfoFromStorage();
  }

  onSidebarToggle(): void {
    this.isSidebarCollapsedChange.emit(!this.isSidebarCollapsed());
  }

  onBack(): void {
    history.back();
  }

  logout(): void {
    this._authService.logout();
  }
}
