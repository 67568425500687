import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { IconComponent, IconName } from '@rp/shared/components';

@Component({
  selector: 'rp-coming-soon',
  standalone: true,
  templateUrl: './coming-soon.component.html',
  styleUrl: './coming-soon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, IconComponent],
})
export class ComingSoonComponent {
  icons = IconName;
}
