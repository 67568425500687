import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map, Observable } from 'rxjs';

import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants';
import { SignInPayload, SignInResponse } from '../models';
import { AuthBaseService } from './auth-base.service';

@Injectable({ providedIn: 'root' })
export class AdminAuthService extends AuthBaseService {
  private readonly _router = inject(Router);

  signIn(payload: SignInPayload): Observable<void> {
    return this.http
      .post<SignInResponse>(`${this.adminApiHost}/admin/auth/token/login/`, payload)
      .pipe(
        map(({ access, refresh }) => {
          this.localStorageService.setValue<string>(ACCESS_TOKEN, access);
          this.localStorageService.setValue<string>(REFRESH_TOKEN, refresh);

          this._router.navigate(['/']);
        }),
      );
  }
}
