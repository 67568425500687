import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { LocalStorageService } from '@rp/shared/services';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

import { ACCESS_TOKEN, PUBLIC_URL_PATHS } from '../constants';
import { AuthBaseService } from '../services/auth-base.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private readonly _authService = inject(AuthBaseService);
  private readonly _localStorageService = inject(LocalStorageService);

  private _refreshTokenInProgress = false;
  private _refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null,
  );

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessTokenBefore = this._localStorageService.getStringValue(ACCESS_TOKEN);

    return next.handle(request).pipe(
      catchError(error => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (this._isPublicRequest(request)) {
          return throwError(() => error);
        }

        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status !== 401) {
          return throwError(() => error);
        }

        if (this._refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this._refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this._addAuthenticationToken(request))),
          );
        } else {
          const accessTokenAfter = this._localStorageService.getStringValue(ACCESS_TOKEN);

          if (accessTokenAfter !== accessTokenBefore) {
            return next.handle(this._addAuthenticationToken(request));
          }

          this._refreshTokenInProgress = true;

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until
          // the new token has been retrieved
          this._refreshTokenSubject.next(null);

          // Call authService.refreshAccessToken (this is an Observable that will be returned)
          return this._authService.refreshToken().pipe(
            switchMap(accessToken => {
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this._refreshTokenInProgress = false;
              this._refreshTokenSubject.next(accessToken);

              return next.handle(this._addAuthenticationToken(request));
            }),
            catchError((err: HttpErrorResponse) => {
              // If refresh token is invalid we just do logout
              if ([400, 401].includes(err.status)) {
                this._refreshTokenInProgress = false;
                this._authService.logout();

                return throwError(() => err);
              }

              return throwError(() => error);
            }),
          );
        }
      }),
    );
  }

  private _addAuthenticationToken(request: HttpRequest<any>) {
    // Get access token from Local Storage
    const accessToken = this._localStorageService.getStringValue(ACCESS_TOKEN);

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  private _isPublicRequest(request: HttpRequest<any>): boolean {
    return PUBLIC_URL_PATHS.some(v => request.url.includes(v));
  }
}
