import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, IconName } from '@rp/shared/components';
import { HeaderStateService } from '@rp/shared/services';

@Component({
  selector: 'rp-mobile-sub-header, [rpMobileSubHeader]',
  standalone: true,
  templateUrl: './mobile-sub-header.component.html',
  styleUrl: './mobile-sub-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, ButtonComponent],
})
export class MobileSubHeaderComponent {
  headerStateService = inject(HeaderStateService);

  icons = IconName;

  onBack(): void {
    if (this.headerStateService.backRoute()) {
      history.back();
    } else {
      this.headerStateService.backClick.emit();
    }
  }
}
