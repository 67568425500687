import { TableColumn } from '@rp/shared/components';

const createdLinksTableColumns: TableColumn[] = [
  {
    columnDef: 'id',
    header: 'general.id',
    withSort: true,
    width: '60px',
    maxWidth: '60px',
    padding: '0 16px 0 12px',
  },
  {
    columnDef: 'date',
    header: 'general.date',
    withSort: true,
    width: '75px',
    maxWidth: '75px',
  },
  {
    columnDef: 'sub1',
    header: 'general.sub1',
    width: '76px',
    maxWidth: '76px',
  },
  {
    columnDef: 'sub2',
    header: 'general.sub2',
    width: '76px',
    maxWidth: '76px',
  },
  {
    columnDef: 'sub3',
    header: 'general.sub3',
    width: '76px',
    maxWidth: '76px',
  },
  {
    columnDef: 'sub4',
    header: 'general.sub4',
    width: '76px',
    maxWidth: '76px',
  },
  {
    columnDef: 'sub5',
    header: 'general.sub5',
    width: '76px',
    maxWidth: '76px',
  },
  {
    columnDef: 'link',
    header: 'general.link',
    width: '150px',
  },
  {
    columnDef: 'copy',
    header: '',
    width: '30px',
    maxWidth: '30px',
    align: 'center',
    padding: '0 6px 0 0',
  },
];

export const CreatedLinksMock = {
  createdLinksTableColumns,
};
