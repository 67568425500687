import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { AuthBaseService } from '../../services/auth-base.service';

@Component({
  selector: 'rp-user-logout',
  standalone: true,
  templateUrl: './user-logout.component.html',
  styleUrl: './user-logout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule],
})
export class UserLogoutComponent {
  private _authBaseService = inject(AuthBaseService);

  logout(): void {
    this._authBaseService.logout();
  }
}
