import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  signal,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatListItem } from '@angular/material/list';

import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@rp/shared/components';

import { MenuListItem } from '../../models';
import { SidebarMenuItemComponent } from '../sidebar-menu-item';
import { SidebarService } from '../../services';

@Component({
  selector: 'rp-sidebar-nav-item',
  standalone: true,
  templateUrl: './sidebar-nav-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatListItem,
    IconComponent,
    RouterLinkActive,
    RouterLink,
    MatMenuTrigger,
    MatMenu,
    SidebarMenuItemComponent,
    MatMenuItem,
    TranslateModule,
  ],
})
export class SidebarNavItemComponent {
  menuItem = input.required<MenuListItem>();

  isDesktop = input.required({ transform: booleanAttribute });

  isSubMenuOpened = signal<boolean>(false);

  private _sidebarService = inject(SidebarService);

  onSubmenuToggle(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    this.isSubMenuOpened.set(!this.isSubMenuOpened());
  }

  onTriggerEnter(menuTrigger: MatMenuTrigger): void {
    if (this.isDesktop()) {
      this._sidebarService.onTriggerEnter(menuTrigger);
    }
  }

  onTriggerLeave(menuTrigger: MatMenuTrigger): void {
    if (this.isDesktop()) {
      this._sidebarService.onTriggerLeave(menuTrigger);
    }
  }

  onMenuEnter(menuTrigger: MatMenuTrigger): void {
    this._sidebarService.onMenuEnter(menuTrigger);
  }

  onMenuLeave(menuTrigger: MatMenuTrigger): void {
    this._sidebarService.onMenuLeave(menuTrigger);
  }
}
