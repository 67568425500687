import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@rp/shared/components';

import { MenuListItem } from '../../models';
import { SidebarService } from '../../services';

@Component({
  selector: 'rp-sidebar-menu-item',
  standalone: true,
  templateUrl: './sidebar-menu-item.component.html',
  styleUrl: './sidebar-menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenuItem, RouterLink, MatMenuTrigger, MatMenu, IconComponent, TranslateModule],
})
export class SidebarMenuItemComponent {
  menuItem = input.required<MenuListItem>();

  private _sidebarService = inject(SidebarService);

  onTriggerEnter(menuTrigger: MatMenuTrigger): void {
    this._sidebarService.onTriggerEnter(menuTrigger);
  }

  onTriggerLeave(menuTrigger: MatMenuTrigger): void {
    this._sidebarService.onTriggerLeave(menuTrigger);
  }

  onMenuEnter(menuTrigger: MatMenuTrigger): void {
    this._sidebarService.onMenuEnter(menuTrigger);
  }

  onMenuLeave(menuTrigger: MatMenuTrigger): void {
    this._sidebarService.onMenuLeave(menuTrigger);
  }
}
