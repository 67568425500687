import { inject, Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { ACCESS_COOKIE, REFRESH_COOKIE, ACCESS_TOKEN, REFRESH_TOKEN } from '../constants';
import { AuthBaseService } from './auth-base.service';

@Injectable({ providedIn: 'root' })
export class UserAuthService extends AuthBaseService {
  private _cookieService = inject(CookieService);

  constructor() {
    super();

    const accessCookie = this._cookieService.get(ACCESS_COOKIE);
    const refreshCookie = this._cookieService.get(REFRESH_COOKIE);

    if (accessCookie && refreshCookie) {
      this.localStorageService.setValue<string>(ACCESS_TOKEN, accessCookie);
      this.localStorageService.setValue<string>(REFRESH_TOKEN, refreshCookie);

      const domainName = this._getDomainNameFromHostname(location.hostname);

      this._cookieService.delete(ACCESS_COOKIE, '/', `.${domainName}`, true, 'None');
      this._cookieService.delete(REFRESH_COOKIE, '/', `.${domainName}`, true, 'None');
    }
  }

  private _getDomainNameFromHostname(hostName: string): string {
    return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
  }
}
