import { Injectable } from '@angular/core';

import { interval, map, ReplaySubject, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClockService {
  readonly nowSubject: ReplaySubject<Date> = new ReplaySubject(1);

  constructor() {
    const time = new Date();

    interval(1000)
      .pipe(
        startWith(time),
        map(() => new Date()),
      )
      .subscribe(this.nowSubject);

    this.nowSubject.next(time);
  }
}
