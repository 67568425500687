import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  input,
  model,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { MatNavList } from '@angular/material/list';
import { AsyncPipe, DatePipe } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent, IconName } from '@rp/shared/components';
import { LogoComponent } from '@rp/shared/ui';
import { environment } from '@rp/environments';
import { ClockService } from '@rp/common/cdk';
import { DateFormat } from '@rp/shared/models';

import { SidebarService } from './services';
import { MenuListItem } from './models';
import { SidebarNavItemComponent } from './components';
import { LanguagesComponent } from '../languages/languages.component';

@Component({
  selector: 'rp-sidebar, [rpSidebar]',
  standalone: true,
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatSidenavContainer,
    LogoComponent,
    IconComponent,
    MatNavList,
    MatSidenav,
    AsyncPipe,
    DatePipe,
    SidebarNavItemComponent,
    LanguagesComponent,
    TranslateModule,
  ],
})
export class SidebarComponent implements OnInit {
  isDesktop = input.required({ transform: booleanAttribute });

  isSidebarCollapsed = model<boolean>(false);

  sidenav = viewChild(MatSidenav);

  menuItemsList = signal<MenuListItem[]>([]);
  footerMenuItemsList = signal<MenuListItem[]>([]);
  socialItemsList = signal<MenuListItem[]>([]);

  currentTime$: Observable<Date>;
  headerConfig = environment.uiConfig.header;
  icons = IconName;
  clockTimeFormat = DateFormat.TimeWithSeconds;

  private _sidebarService = inject(SidebarService);
  private _clockService = inject(ClockService);
  private _destroyRef = inject(DestroyRef);
  private _router = inject(Router);

  constructor() {
    effect(() => {
      if (!this.isDesktop()) {
        this._subscribeToRouteChange();

        if (this.isSidebarCollapsed()) {
          this.sidenav().open();
        } else {
          this.sidenav().close();
        }
      }
    });

    this.currentTime$ = this._clockService.nowSubject;
  }

  ngOnInit(): void {
    this._getItems();
  }

  handleLanguageChange(): void {
    this._closeSidebar();
  }

  private _getItems(): void {
    this.menuItemsList.set(this._sidebarService.getMenuItems());

    this.footerMenuItemsList.set(this._sidebarService.getFooterMenuItems());

    this.socialItemsList.set(this._sidebarService.getSocialItems());
  }

  private _closeSidebar(): void {
    if (!this.isDesktop() && this.sidenav().opened) {
      this.sidenav().close();
      this.isSidebarCollapsed.set(false);
    }
  }

  private _subscribeToRouteChange(): void {
    this._router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe(() => this._closeSidebar());
  }
}
