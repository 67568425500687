@let showBack = headerStateService.showBack();
@let title = headerStateService.title();

<div class="header__wrapper-title">
  @if (showBack) {
    <rp-button class="header__back" type="text" [iconName]="icons.ArrowLeft" size="s" (click)="onBack()">
      {{ 'general.back' | translate }}
    </rp-button>
  }

  <div class="header__caption" [class.header__back-title]="showBack">
    {{ title | translate }}
  </div>
</div>
