import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { LocalStorageService } from '@rp/shared/services';
import { Observable } from 'rxjs';

import { ACCESS_TOKEN } from '../constants/local-storage.constants';

@Injectable()
export class ApplyTokenInterceptor implements HttpInterceptor {
  private _localStorageService = inject(LocalStorageService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('X-Skip-Interceptor')) {
      return next.handle(request);
    }

    const accessToken = this._localStorageService.getStringValue(ACCESS_TOKEN);

    const authReq = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return next.handle(authReq);
  }
}
